module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-graph/gatsby-browser.js'),
      options: {"plugins":[],"language":"mermaid","theme":"dark"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VN6SV36594"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kostas Bariotis","short_name":"KBariotis","start_url":"/","theme_color":"#676d9c","display":"minimal-ui","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicon/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicon/apple-touch-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/favicon/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicon/apple-touch-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/favicon/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicon/apple-touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/favicon/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicon/apple-touch-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicon/favicon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
